import { DeformSlotData } from "./DeformSlotData";
/**
 * @internal
 */
export class DeformAniData {

	skinName: string;
	deformSlotDataList: DeformSlotData[] = [];

	//TODO:coverage
	constructor() {

	}

}


