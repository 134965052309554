/**
 * @internal
 */
//TODO:coverage
export class TfConstraintData {

	name: string;
	boneIndexs: number[] = [];
	targetIndex: number;
	rotateMix: number;
	translateMix: number;
	scaleMix: number;
	shearMix: number;
	offsetRotation: number;
	offsetX: number;
	offsetY: number;
	offsetScaleX: number;
	offsetScaleY: number;
	offsetShearY: number;

}


