/**
 * @internal
 */
export class DrawOrderData {
	time: number;
	drawOrder: number[] = [];

	//TODO:coverage
	constructor() {
	}
}

