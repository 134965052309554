/**
 * @internal
 */
export class AnimationState {
	static stopped: number = 0;
	static paused: number = 1;
	static playing: number = 2;

	constructor() {

	}
}

